import mirDark3XWebp from './MirDark@3x.webp';
import mirDark3XPng from './MirDark@3x.png';
import mirDark2XWebp from './MirDark@2x.webp';
import mirDark2XPng from './MirDark@2x.png';
import mirDark1XWebp from './MirDark@1x.webp';
import mirDark1XPng from './MirDark@1x.png';
export const mirDarkImage = {
    src: mirDark1XPng.src || mirDark1XPng,
    width: 77,
    height: 22,
    sources: [
        { width: 77, height: 22, srcSet: mirDark3XWebp.src || mirDark3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 77, height: 22, srcSet: mirDark3XPng.src || mirDark3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 77, height: 22, srcSet: mirDark2XWebp.src || mirDark2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 77, height: 22, srcSet: mirDark2XPng.src || mirDark2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 77, height: 22, srcSet: mirDark1XWebp.src || mirDark1XWebp },
        { width: 77, height: 22, srcSet: mirDark1XPng.src || mirDark1XPng },
    ],
};
