import { modalManagerModel } from '@common/services/modal-manager';
import { showConditionModal } from '../lib/modals';
export const getCondition = {
    user_buy_upsell: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_buy_upsell_modal',
            firstButton: {
                analyticClick: 'user_buy_upsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Перейти к оплате',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Мы учтём остаток средств по текущей подписке и переключим вас на новую сразу после оплаты',
        });
    },
    user_with_trial_buy_upsell: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_with_trial_buy_upsell_modal',
            firstButton: {
                analyticClick: 'user_with_trial_buy_upsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Перейти к оплате',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Мы переключим вас на новую подписку сразу после оплаты, текущий пробный период перестанет действовать.',
        });
    },
    community_owner_buy_upsell: ({ handler }) => {
        showConditionModal({
            analyticShowAttribute: 'community_owner_buy_upsell_modal',
            firstButton: {
                analyticClick: 'community_owner_buy_upsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Перейти к оплате',
            },
            secondButton: {
                analyticClick: 'community_owner_buy_upsell_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Текущая подписка отключится у всех участников`,
            subheader: 'Другие участники больше не смогут использовать общую подписку. Новая подписка подключится сразу после оплаты.',
        });
    },
    user_with_free_sub_buy_upsell: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_with_free_sub_buy_upsell_modal',
            firstButton: {
                analyticClick: 'user_with_free_sub_buy_upsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Новая подписка подключится сразу. Текущая подписка встанет в очередь и возобновится, если отключить продление новой.',
        });
    },
    user_with_long_sub_buy_upsell: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_with_long_sub_buy_upsell',
            firstButton: {
                analyticClick: 'user_with_long_sub_buy_upsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Новая подписка подключится сразу. Текущая подписка встанет в очередь и возобновится, если отключить продление новой.',
        });
    },
    user_buy_downsell: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_buy_downsell_modal',
            firstButton: {
                analyticClick: 'user_buy_downsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Перейти',
            },
            secondButton: {
                analyticClick: 'user_buy_downsell_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Вы сможете пользоваться преимуществами текущей подписки до конца оплаченного периода. Новая встанет в очередь.',
        });
    },
    community_owner_buy_downsell: ({ handler }) => {
        showConditionModal({
            analyticShowAttribute: 'community_owner_buy_downsell_modal',
            firstButton: {
                analyticClick: 'community_owner_buy_downsell_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            secondButton: {
                analyticClick: 'community_owner_buy_downsell_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Текущая подписка отключится у всех участников`,
            subheader: 'Все участники смогут пользоваться общей подпиской до конца оплаченного периода. После для вас активируется новая подписка.',
        });
    },
    community_owner_apply_promo: ({ handler, subscriptionDateEnd }) => {
        showConditionModal({
            analyticShowAttribute: 'community_owner_apply_promo_modal',
            firstButton: {
                analyticClick: 'community_owner_apply_promo_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Применить промокод',
            },
            secondButton: {
                analyticClick: 'community_owner_apply_promo_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Оставить подписку на двоих',
            },
            header: `Вы уверены, что хотите отменить подписку?`,
            subheader: `У вас активна подписка на двоих. Второй участник подписки лишится её преимуществ, если вы примените промокод. Подписка по промокоду заработает после окончания текущей — ${subscriptionDateEnd}.`,
        });
    },
    community_owner_apply_promo_suspend: ({ handler }) => {
        showConditionModal({
            analyticShowAttribute: 'community_owner_apply_promo_suspend_modal',
            firstButton: {
                analyticClick: 'community_owner_apply_promo_suspend_modal-button_confirm',
                handler,
                mode: 'secondary',
                children: 'Применить промокод',
            },
            secondButton: {
                analyticClick: 'community_owner_apply_promo_suspend_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'primary',
                children: 'Оставить текущую',
            },
            header: `Подключить новую подписку по промокоду?`,
            subheader: `Сейчас у вас активна подписка на двоих. Если вы примените промокод и смените тариф, второй участник подписки лишится её преимуществ. Новая подписка заработает сразу после применения промокода.`,
        });
    },
    community_member_apply_promo: ({ handler, subscriptionDateEnd }) => {
        showConditionModal({
            analyticShowAttribute: 'community_member_apply_promo_suspend_modal',
            firstButton: {
                analyticClick: 'community_member_apply_promo_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Применить промокод',
            },
            secondButton: {
                analyticClick: 'community_member_apply_promo_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Оставить подписку на двоих',
            },
            header: `Вы уверены, что хотите отменить подписку?`,
            subheader: `У вас активна подписка на двоих. Вы лишитесь её преимуществ, если примените промокод. Подписка по промокоду заработает после окончания текущей — ${subscriptionDateEnd}.`,
        });
    },
    community_member_apply_promo_suspend: ({ handler }) => {
        showConditionModal({
            analyticShowAttribute: 'community_member_apply_promo_suspend_modal',
            firstButton: {
                analyticClick: 'community_member_apply_promo_suspend_modal-button_confirm',
                handler,
                mode: 'secondary',
                children: 'Применить промокод',
            },
            secondButton: {
                analyticClick: 'community_member_apply_promo_suspend_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'primary',
                children: 'Оставить текущую',
            },
            header: `Подключить новую подписку по промокоду?`,
            subheader: `Сейчас у вас активна подписка на двоих. Если примените промокод и смените тариф, снова стать участником совместной подписки можно будет только по приглашению владельца. Новая подписка заработает сразу после применения промокода.`,
        });
    },
    user_in_suspend_buy_sub: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_in_suspend_buy_sub_modal',
            firstButton: {
                analyticClick: 'user_in_suspend_buy_sub_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            secondButton: {
                analyticClick: 'user_in_suspend_buy_sub_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Текущая подписка будет остановлена. Новая подписка заработает сразу после оплаты',
        });
    },
    community_owner_in_suspend_buy_sub: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'community_owner_in_suspend_buy_sub_modal',
            firstButton: {
                analyticClick: 'community_owner_in_suspend_buy_sub_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            secondButton: {
                analyticClick: 'community_owner_in_suspend_buy_sub_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Сейчас у вас активна подписка на двоих. Если вы подключите новую подписку, второй участник подписки лишится её преимуществ. Новая подписка заработает сразу после оплаты',
        });
    },
    community_member_in_suspend_buy_sub: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'community_member_in_suspend_buy_sub_modal',
            firstButton: {
                analyticClick: 'community_member_in_suspend_buy_sub_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            secondButton: {
                analyticClick: 'community_member_in_suspend_buy_sub_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Сейчас у вас активна подписка на двоих. Если смените тариф, снова стать участником совместной подписки можно будет только по приглашению владельца. Новая подписка заработает сразу после оплаты',
        });
    },
    community_member_buy_sub: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'community_member_buy_sub_modal',
            firstButton: {
                analyticClick: 'community_member_buy_sub_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'Перейти к оплате',
            },
            secondButton: {
                analyticClick: 'community_member_buy_sub_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: 'Сейчас у вас активна подписка на двоих. Если смените тариф, снова стать участником совместной подписки можно будет только по приглашению владельца. Новая подписка заработает сразу после оплаты',
        });
    },
    user_buy_community: ({ handler, subscriptionName }) => {
        showConditionModal({
            analyticShowAttribute: 'user_buy_community_modal',
            firstButton: {
                analyticClick: 'user_buy_community_modal-button_confirm',
                handler,
                mode: 'primary',
                children: 'К оплате',
            },
            secondButton: {
                analyticClick: 'user_buy_community_modal-button_cancel',
                handler: () => modalManagerModel.closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
            header: `Перейти на подписку ${subscriptionName}?`,
            subheader: `Текущая подписка будет остановлена и возобновится после отмены подписки ${subscriptionName}.`,
        });
    },
};
export const conditionManagerModel = {
    getCondition,
};
