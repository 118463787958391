import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { RecordIcon } from './fc-icons';
import { analyticsDictionary } from '../../analytics-dictionary';
import { BenefitsCancelSubscription, BenefitsCancelSubscriptionActions } from './ui';
const getPayloadToManagerModal = (params) => {
    const { cancelSubscriptionHandler, cancelButtonText } = params;
    return {
        analyticShowAttribute: analyticsDictionary.modalCancelSubscriptionBenefitsShow,
        icon: <RecordIcon />,
        children: <BenefitsCancelSubscription />,
        actions: (<BenefitsCancelSubscriptionActions cancelButtonText={cancelButtonText} cancelSubscriptionHandler={cancelSubscriptionHandler}/>),
    };
};
export const showBenefitsCancelSubscription = (params) => modalManagerModel.showModal(getPayloadToManagerModal(params));
