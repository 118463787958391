import React from 'react';
import { useUnit } from 'effector-react';
import { userDataModel } from '@common/entities/user-data';
import { groupSubscriptionMembersModel } from '../../model';
import { default as MembersListSimpleTemplate } from './members-list-simple';
import { showSearchFriendsModal } from '../../lib';
export { createMemberStatusMapping } from './lib/create-member-status-mapping';
export const MembersListSimple = () => {
    const store = useUnit(groupSubscriptionMembersModel.$members);
    const userDataStore = useUnit(userDataModel.$userData);
    return (<MembersListSimpleTemplate addMember={() => showSearchFriendsModal()} members={store.items} isOwner={store.ownerVkId === userDataStore.vkId} maxMembers={store.capacity}/>);
};
