import React from 'react';
import { CustomImg } from '@common/shared/ui/custom-img';
import { mastercardDarkImage } from './dark';
import { mastercardLightImage } from './light';
import { useConfigProvider } from '@vkontakte/vkui';
const Icon = (props) => {
    const { appearance } = useConfigProvider();
    const icon = appearance === 'light' ? mastercardLightImage : mastercardDarkImage;
    return (<CustomImg {...icon} {...props} alt={'Иконка платежной системы Mastercard'}/>);
};
export default Icon;
