import vkPayLight3XWebp from './VK-Pay-light@3x.webp';
import vkPayLight3XPng from './VK-Pay-light@3x.png';
import vkPayLight2XWebp from './VK-Pay-light@2x.webp';
import vkPayLight2XPng from './VK-Pay-light@2x.png';
import vkPayLight1XWebp from './VK-Pay-light@1x.webp';
import vkPayLight1XPng from './VK-Pay-light@1x.png';
export const vkPayLightImage = {
    src: vkPayLight1XPng.src || vkPayLight1XPng,
    width: 93,
    height: 32,
    sources: [
        {
            width: 93,
            height: 32,
            srcSet: vkPayLight3XWebp.src || vkPayLight3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 93,
            height: 32,
            srcSet: vkPayLight3XPng.src || vkPayLight3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 93,
            height: 32,
            srcSet: vkPayLight2XWebp.src || vkPayLight2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 93,
            height: 32,
            srcSet: vkPayLight2XPng.src || vkPayLight2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 93, height: 32, srcSet: vkPayLight1XWebp.src || vkPayLight1XWebp },
        { width: 93, height: 32, srcSet: vkPayLight1XPng.src || vkPayLight1XPng },
    ],
};
