import React from 'react';
import { createEffect, createEvent, sample } from 'effector';
import { Icon28CheckCircleOutline } from '@vkontakte/icons';
import { snackbarManagerModel } from '@common/services/snackbar-manager';
import { api } from '../../api';
import { addMember } from './addMember';
import { clearMember } from './clearMember';
import { changeStateMember } from './changeStateMember';
export const sendMember = createEvent();
export const sendMemberFx = createEffect(async (friend) => {
    addMember({ ...friend, state: 'loading', status: 'invitation_sent' });
    try {
        const result = (await api.addMember(friend.userId)).data;
        if (result.successful) {
            changeStateMember({ userId: friend.userId, state: 'idle' });
            snackbarManagerModel.addSnack({
                before: <Icon28CheckCircleOutline fill='var(--vkui--color_icon_positive)'/>,
                children: 'Приглашение отправлено',
            });
            return;
        }
        clearMember(friend.userId);
        snackbarManagerModel.addSnackError({
            subtitle: 'Не удалось отправить приглашение в подписку',
        });
    }
    catch {
        clearMember(friend.userId);
        snackbarManagerModel.addSnackError({
            subtitle: 'Не удалось отправить приглашение в подписку',
        });
    }
});
sample({ clock: sendMember, target: [sendMemberFx] });
