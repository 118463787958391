import React, { useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Seo } from '@website/shared/ui/molecules/seo';
import { AdaptivityProvider, AppRoot, ButtonGroup, ConfigProvider, Platform, SizeType, SplitCol, SSRWrapper, usePlatform, } from '@vkontakte/vkui';
import { useRouter } from 'next/router';
import { useReportWebVitals } from 'next/web-vitals';
import { useUnit } from 'effector-react';
import apiCommon from '@common/utils/ApiCommon';
import { analyticsDictionary, createAnalyticClickAttribute, reportWebVitals } from '@common/utils/Analytics';
import { useCheckUserAgentPlatform } from '@common/shared/hooks/useCheckUserAgentPlatform';
import '@common/shared/lib/setVh';
import { KeywordsKeeper } from '@common/entities/offers';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { AuthStateModel } from '@common/entities/auth-state';
import { initAppAuth, showAuthErrorModal } from '@common/features/auth-user';
import { UrlHashChecker } from '@common/features/student';
import { getNotificationSubscription } from '@common/application/lib/initCentrifuge';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { getDataPulling } from '@common/components/Root/actions';
import { localStorageAdapter } from '@common/shared/lib/storage-adapters';
import { ApplicationConfigCommon } from '@common/widgets/application-config-common';
import { GENERAL_META_TAGS } from '@website/application/constants/general-meta-tags';
import { getHashFromNextRouter } from '@website/shared/lib/getHashFromNextRouter';
import { ROUTES } from '@website/shared/lib/routes';
import { TARIFFS_ROUTE } from '@website/shared/lib/profile-routes';
import { UserApi } from '@website/entities/user';
import { ProfileTemplateModel } from '@website/template/profile-template';
import { KEYWORDS_FOR_KEYWORDS_KEEPER } from '@website/application/constants/keywords-for-keywords-keeper';
import { useApplyOfferObserver } from '@website/application/hooks';
import { PrivacyPolicyChecker } from '@website/application/ui/privacy-policy-checker';
import { useEffectOnceByCondition } from '@common/shared/hooks/useEffectOnceByCondition';
import '../shared/styles/index.scss';
import { useMyTrackerRequest } from '@common/shared/hooks/useMyTrackerRequest';
import '@website/application/configuration';
import '@common/application/observers/young-subscription-observe';
import { mytrackerManager } from '@common/utils/mytracker-manager';
import storage from '@common/utils/Storage';
import { initSentry } from '@common/utils/Sentry';
import styles from './styles.module.scss';
import { StartStudentFlowModel } from '@common/features/start-student-flow';
import { CheckoutModalDetector } from '@website/application/ui';
import { useInsets } from '@website/application/hooks/useInsets';
initSentry({ storage: localStorageAdapter(storage) });
apiCommon.setHeader('x-app-theme', 'dark');
apiCommon.addListener('authServiceError', () => {
    showAuthErrorModal();
});
const AUTH_AVOID_PAGES = [ROUTES.cis, ROUTES.app, ROUTES.rules, ROUTES.serviceOnMaintenance];
const SUBSCRIPTIONS_AVOID_PAGES = [
    ROUTES.profile,
    ROUTES.tariffs,
    ROUTES.notifications,
    ROUTES.cis,
    ROUTES.app,
    ROUTES.rules,
    ROUTES.notFound,
    ROUTES.serviceOnMaintenance,
];
const Application = ({ Component, pageProps }) => {
    const platform = usePlatform();
    const authState = useUnit(AuthStateModel.$authState);
    const getSubscriptionStatus = useUnit(SubscriptionsModel.getSubscriptionStatusFx);
    const getSubscriptions = useUnit(SubscriptionsModel.getSubscriptionsFx);
    const getCurrentSubscriptionInfoFx = useUnit(SubscriptionsModel.getCurrentSubscriptionInfoFx);
    const getCurrentPath = useUnit(ProfileTemplateModel.getCurrentPath);
    const { pathname, asPath, push, query } = useRouter();
    useReportWebVitals(reportWebVitals);
    useMyTrackerRequest(pathname);
    useEffect(() => {
        getCurrentPath(pathname);
    }, [pathname]);
    useEffectOnceByCondition(() => {
        if (AUTH_AVOID_PAGES.includes(pathname)) {
            return false;
        }
        const init = async () => {
            await initAppAuth();
        };
        void init();
        KeywordsKeeper.initKeywords(KEYWORDS_FOR_KEYWORDS_KEEPER);
        return true;
    }, [pathname]);
    useEffectOnceByCondition(() => {
        if (SUBSCRIPTIONS_AVOID_PAGES.includes(pathname)) {
            return false;
        }
        if (authState === 'authorized') {
            void Promise.allSettled([getSubscriptions(), getSubscriptionStatus()]);
            return true;
        }
        return false;
    }, [authState, pathname]);
    useEffect(() => {
        if (authState === 'authorized') {
            void Promise.allSettled([UserApi.getUserDataProfileFx(), getCurrentSubscriptionInfoFx()]);
            getNotificationSubscription().then((sub) => {
                if (sub) {
                    sub
                        .on('publication', (ctx) => {
                        const res = ctx.data;
                        const { data } = res;
                        if (res.type === 'apply_promocode_done') {
                            void getDataPulling({ all: true });
                            modalManagerModel.showModal({
                                analyticShowAttribute: analyticsDictionary.modalPromocodeSucces,
                                header: data.title,
                                subheader: data.body,
                                mode: 'success',
                                actions: (<ButtonGroup mode='vertical' gap='m' stretched={true}>
                      {data.buttons.map((button) => {
                                        if (button.attributes.type === 'close') {
                                            return (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalPromocodeSuccesClickClose)} key={button.id} size={'l'} stretched={true} onClick={() => modalManagerModel.closeModal()} className={styles.button}>
                              {button.attributes.title}
                            </ModalButton>);
                                        }
                                    })}
                    </ButtonGroup>),
                            });
                        }
                    })
                        .subscribe();
                }
            });
        }
    }, [authState]);
    useEffect(() => {
        void mytrackerManager.eventPageView();
    }, [asPath]);
    useApplyOfferObserver();
    const isMobilePlatform = useCheckUserAgentPlatform() === 'mobile';
    const hash = getHashFromNextRouter();
    const confirmedStatusCallback = async () => {
        await push(TARIFFS_ROUTE);
    };
    const cleanHash = async () => {
        await push(pathname);
    };
    useEffect(() => {
        if (authState === 'authorized' || authState === 'unauthorized') {
            StartStudentFlowModel.checkHashAndStartFlow({
                hash,
                queryParams: new URLSearchParams(query),
                callbackAfterFinishedFlow: cleanHash,
                strategyCallback: confirmedStatusCallback,
            });
        }
    }, [authState, hash, query]);
    const insets = useInsets();
    return (<ErrorBoundary>
      <SSRWrapper>
        <ConfigProvider appearance={'dark'} platform={isMobilePlatform ? platform : Platform.VKCOM}>
          <AdaptivityProvider sizeY={SizeType.REGULAR}>
            <AppRoot safeAreaInsets={insets}>
              <ApplicationConfigCommon>
                <SplitCol>
                  <UrlHashChecker hash={hash} confirmedStatusCallback={confirmedStatusCallback} cleanHash={cleanHash}>
                    <PrivacyPolicyChecker>
                      <Seo title={'Подписка на VK Музыку'} metaTags={GENERAL_META_TAGS}/>
                      <CheckoutModalDetector>
                        <Component {...pageProps}/>
                      </CheckoutModalDetector>
                    </PrivacyPolicyChecker>
                  </UrlHashChecker>
                </SplitCol>
              </ApplicationConfigCommon>
            </AppRoot>
          </AdaptivityProvider>
        </ConfigProvider>
      </SSRWrapper>
    </ErrorBoundary>);
};
export default Application;
