import React from 'react';
import { useGate, useUnit } from 'effector-react';
import { Button } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@apps/common/utils/Analytics';
import { offerButtonModel } from './model';
export const OfferButton = () => {
    useGate(offerButtonModel.Gate);
    const { analyticClick, handler, mode, children } = useUnit(offerButtonModel.$buttonData);
    const loading = useUnit(offerButtonModel.$buttonLoading);
    return (<Button {...createAnalyticClickAttribute(analyticClick)} loading={loading} disabled={loading} mode={mode} onClick={handler}>
      {children}
    </Button>);
};
