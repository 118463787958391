import mirLight3XWebp from './MirLight@3x.webp';
import mirLight3XPng from './MirLight@3x.png';
import mirLight2XWebp from './MirLight@2x.webp';
import mirLight2XPng from './MirLight@2x.png';
import mirLight1XWebp from './MirLight@1x.webp';
import mirLight1XPng from './MirLight@1x.png';
export const mirLightImage = {
    src: mirLight1XPng.src || mirLight1XPng,
    width: 77,
    height: 22,
    sources: [
        { width: 77, height: 22, srcSet: mirLight3XWebp.src || mirLight3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 77, height: 22, srcSet: mirLight3XPng.src || mirLight3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 77, height: 22, srcSet: mirLight2XWebp.src || mirLight2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 77, height: 22, srcSet: mirLight2XPng.src || mirLight2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 77, height: 22, srcSet: mirLight1XWebp.src || mirLight1XWebp },
        { width: 77, height: 22, srcSet: mirLight1XPng.src || mirLight1XPng },
    ],
};
