import apiCommon from '@common/utils/ApiCommon';
const getYouthSubscriptionData = () => {
    return apiCommon
        .makeGetRequest(`/api/user/youth-subscription-status`, undefined, undefined, undefined)
        .then((res) => res.data);
};
const bannerEnabled = ({ bannerCode }) => {
    return apiCommon
        .makePostRequest(`/api/user/banners/${bannerCode}/enable`, undefined, undefined, undefined)
        .then((res) => res.data);
};
const bannerDisable = ({ bannerCode }) => {
    return apiCommon
        .makePostRequest(`/api/user/banners/${bannerCode}/disable`, undefined, undefined, undefined)
        .then((res) => res.data);
};
export const api = {
    getYouthSubscriptionData,
    bannerEnabled,
    bannerDisable,
};
