import icon80ForTwo3XWebp from './icon-80-for-two@3x.webp';
import icon80ForTwo3XPng from './icon-80-for-two@3x.png';
import icon80ForTwo2XWebp from './icon-80-for-two@2x.webp';
import icon80ForTwo2XPng from './icon-80-for-two@2x.png';
import icon80ForTwo1XWebp from './icon-80-for-two@1x.webp';
import icon80ForTwo1XPng from './icon-80-for-two@1x.png';
export const icon80ForTwoImage = {
    src: icon80ForTwo1XPng.src || icon80ForTwo1XPng,
    width: 80,
    height: 80,
    sources: [
        {
            width: 80,
            height: 80,
            srcSet: icon80ForTwo3XWebp.src || icon80ForTwo3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80ForTwo3XPng.src || icon80ForTwo3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80ForTwo2XWebp.src || icon80ForTwo2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80ForTwo2XPng.src || icon80ForTwo2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 80, height: 80, srcSet: icon80ForTwo1XWebp.src || icon80ForTwo1XWebp },
        { width: 80, height: 80, srcSet: icon80ForTwo1XPng.src || icon80ForTwo1XPng },
    ],
};
