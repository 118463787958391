import { attach, createStore, sample } from 'effector';
import { combineEvents } from 'patronum';
import { SubscriptionsModel } from '@apps/common/entities/subscriptions/model';
import { youngSubscriptionModel } from '../../../model';
import { $verificationStatus } from '../../../model/verification-status';
import { contentMap } from './content-map';
const { getYoungSubscriptionDataFx } = youngSubscriptionModel;
const $showBanner = createStore(false);
$showBanner.on(getYoungSubscriptionDataFx.doneData, (_, payload) => payload.showBanner);
export const getBannerDataFx = attach({
    source: {
        verificationStatus: $verificationStatus,
        subscriptionsListWithBuffer: SubscriptionsModel.$subscriptionsListWithBuffer,
    },
    effect: ({ verificationStatus, subscriptionsListWithBuffer }) => {
        if (subscriptionsListWithBuffer.find((subscription) => subscription?.features?.young?.is_young_subscription)) {
            return null;
        }
        switch (verificationStatus) {
            case 'completed':
            case 'failed': {
                return contentMap[verificationStatus];
            }
            default: {
                return null;
            }
        }
    },
});
sample({
    clock: combineEvents([$showBanner.updates, SubscriptionsModel.$subscriptionsListWithBuffer.updates]),
    source: {
        showBanner: $showBanner,
    },
    filter: ({ showBanner }) => showBanner,
    target: getBannerDataFx,
});
