import React from 'react';
import { useGate } from 'effector-react';
import classNames from 'classnames';
import { Avatar, CellButton, GridAvatar } from '@vkontakte/vkui';
import { Icon28AddOutline } from '@vkontakte/icons';
import { groupSubscriptionMembersModel } from '../../model';
import { getMemberStatusContent } from './lib/member-status-mapping';
import styles from './styles.module.scss';
const MembersListSimple = (props) => {
    const { maxMembers, members, addMember, isOwner } = props;
    useGate(groupSubscriptionMembersModel.gate);
    const emptyFriend = Array(Math.max(maxMembers - members.length, 0)).fill(null);
    return (<div>
      <div className={styles.friendsSubscriptionSimple}>
        {members.map((item) => (<GridAvatar key={item.userId} src={[item.defaultAvatarUrl]} className={styles.friendsSubscriptionSimple__friend}>
            {getMemberStatusContent(item.status)}
          </GridAvatar>))}
        {isOwner &&
            emptyFriend.map((_item, index) => (<div key={index} className={classNames(styles.friendsSubscriptionSimple__friend, styles.friendsSubscriptionSimple__friend_empty)}/>))}
      </div>

      {isOwner && maxMembers - members.length > 0 && (<div className={styles.friendsSubscriptionSimple__addWrapper}>
          <CellButton before={<Avatar noBorder={true} size={48}>
                <Icon28AddOutline />
              </Avatar>} onClick={addMember}>
            Пригласить друга
          </CellButton>
        </div>)}
    </div>);
};
export default MembersListSimple;
