import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { api } from '../api';
import { setParamsStoreConcelSubscriptionFlow } from './params-store-cancel-subscription-flow';
import { cancelGroupSubscriptionFlow } from './cancel-group-subscription-flow';
import { cancelBaseFlow } from './cancel-base-subscription-flow';
import { winback0325Flow } from './winback-0325-flow';
const getIsGroupSubscriptionState = () => SubscriptionsModel.$activeSubscription.getState()?.features?.group?.is_group_subscription;
export const cancelFlow = async (params) => {
    setParamsStoreConcelSubscriptionFlow(params);
    const { subscriptionId, isPauseAvailable } = params;
    const isGroupSubscription = getIsGroupSubscriptionState();
    if (isGroupSubscription) {
        cancelGroupSubscriptionFlow({ subscriptionId, isPauseAvailable });
        return;
    }
    const winbackVariant = await api.getWinbackAb0325().catch(() => {
        return 'none';
    });
    if (winbackVariant !== 'none') {
        await winback0325Flow({
            subscriptionId: subscriptionId,
            variantAb: winbackVariant,
        });
        return;
    }
    cancelBaseFlow({ subscriptionId, isPauseAvailable });
};
