const DEFAULT_VALUE = {
    subscriptionDetails: '',
    studentsRejected: '',
    studentStatusInfoBannerKeyword: '',
    leaveGroupSubscriptionSuccessKeyword: '',
    cancelGroupSubscriptionSuccessKeyword: '',
    profileAvailableKeyword: '',
    profileNotAvailableKeyword: '',
    youngRejected: '',
    youngSubscription: '',
};
class KeywordsKeeper {
    constructor() {
        this.keywords = DEFAULT_VALUE;
        this.resolve = () => {
            return;
        };
        this.initPromise = new Promise((resolve) => {
            this.resolve = resolve;
        });
        this.initKeywords = (keywords) => {
            this.keywords = keywords;
            this.resolve();
        };
        this.setKeywords = (keywords) => {
            this.initPromise = new Promise((resolve) => {
                this.resolve = resolve;
            });
            this.keywords = { ...this.keywords, ...keywords };
            this.resolve();
        };
        this.getKeywordsByDictionaryKey = async (key) => {
            await this.initPromise;
            return this.keywords[key];
        };
        this.getKeywords = async () => {
            await this.initPromise;
            return this.keywords;
        };
    }
}
export default new KeywordsKeeper();
