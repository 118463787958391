import vkPayDark3XWebp from './VK-Pay-dark@3x.webp';
import vkPayDark3XPng from './VK-Pay-dark@3x.png';
import vkPayDark2XWebp from './VK-Pay-dark@2x.webp';
import vkPayDark2XPng from './VK-Pay-dark@2x.png';
import vkPayDark1XWebp from './VK-Pay-dark@1x.webp';
import vkPayDark1XPng from './VK-Pay-dark@1x.png';
export const vkPayDarkImage = {
    src: vkPayDark1XPng.src || vkPayDark1XPng,
    width: 93,
    height: 32,
    sources: [
        {
            width: 93,
            height: 32,
            srcSet: vkPayDark3XWebp.src || vkPayDark3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        { width: 93, height: 32, srcSet: vkPayDark3XPng.src || vkPayDark3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        {
            width: 93,
            height: 32,
            srcSet: vkPayDark2XWebp.src || vkPayDark2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 93, height: 32, srcSet: vkPayDark2XPng.src || vkPayDark2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 93, height: 32, srcSet: vkPayDark1XWebp.src || vkPayDark1XWebp },
        { width: 93, height: 32, srcSet: vkPayDark1XPng.src || vkPayDark1XPng },
    ],
};
