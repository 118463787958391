import React from 'react';
import { MembersListAvatarOverlay } from '../../members-list-avatar-overlay';
import { Badge } from '../ui/badge';
export const memberStatusMapping = {
    owner: <Badge />,
    invitation_sent: <MembersListAvatarOverlay type={'invitation_sent'}/>,
    invitation_rejected: <MembersListAvatarOverlay type={'invitation_rejected'}/>,
    member: null,
};
export const getMemberStatusContent = (status) => {
    return memberStatusMapping[status];
};
