import React from 'react';
import { useGate, useUnit } from 'effector-react';
import WarningBanner from '@apps/common/components/common/WarningBanner';
import { $verificationStatus } from '../../model/verification-status';
import { youngStatusInfoBannerModel } from './model';
import { OfferButton } from './ui';
export const YoungStatusInfoBanner = () => {
    useGate(youngStatusInfoBannerModel.Gate);
    const bannerData = useUnit(youngStatusInfoBannerModel.$bannerData);
    const verificationStatus = useUnit($verificationStatus);
    if (!bannerData) {
        return null;
    }
    const { title, description, onClose, theme } = bannerData;
    return (<WarningBanner type={theme} title={title} description={description} onClose={onClose} footer={verificationStatus === 'completed' && <OfferButton />}/>);
};
