import { createEffect, createEvent, sample } from 'effector';
import { ParamsOrHashGetter } from '@common/utils/params-or-hash-getter';
import { youngSubscriptionModel } from '@common/entities/young-subscription';
import { HASH_PART_STUDENTS_BY_EMAIL, HASH_PART_STUDENTS_BY_EMAIL_TOKEN, HASH_PART_STUDENTS_YOUTH_STATUS_VERIFICATION, } from '@common/features/student/ui/url-hash-checker/lib';
import { emailStudentFlowModel } from './email-student-flow';
import { IS_MINIAPP } from '@common/consts';
import router from 'next/router';
export const checkHashAndStartFlow = createEvent();
const checkHashFx = createEffect((payload) => {
    const { hash, callbackAfterFinishedFlow, queryParams } = payload;
    const paramsOrHashGetter = new ParamsOrHashGetter(hash);
    if (queryParams?.has(HASH_PART_STUDENTS_YOUTH_STATUS_VERIFICATION) ||
        paramsOrHashGetter.has(HASH_PART_STUDENTS_YOUTH_STATUS_VERIFICATION)) {
        if (!IS_MINIAPP) {
            void router.push(router.pathname);
        }
        youngSubscriptionModel.startYoungVerificationFlow();
        return;
    }
    if (paramsOrHashGetter.has(HASH_PART_STUDENTS_BY_EMAIL)) {
        emailStudentFlowModel.startEmailStudentFlow({ callbackAfterFinishedFlow });
        return;
    }
    if (paramsOrHashGetter.has(HASH_PART_STUDENTS_BY_EMAIL_TOKEN)) {
        emailStudentFlowModel.startConfirmingEmailFlow({ hash, callbackAfterFinishedFlow });
        return;
    }
});
sample({ clock: checkHashAndStartFlow, target: checkHashFx });
