import icon80Wink3XWebp from './icon-80-wink@3x.webp';
import icon80Wink3XPng from './icon-80-wink@3x.png';
import icon80Wink2XWebp from './icon-80-wink@2x.webp';
import icon80Wink2XPng from './icon-80-wink@2x.png';
import icon80Wink1XWebp from './icon-80-wink@1x.webp';
import icon80Wink1XPng from './icon-80-wink@1x.png';
export const icon80WinkImage = {
    src: icon80Wink1XPng.src || icon80Wink1XPng,
    width: 80,
    height: 80,
    sources: [
        {
            width: 80,
            height: 80,
            srcSet: icon80Wink3XWebp.src || icon80Wink3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Wink3XPng.src || icon80Wink3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Wink2XWebp.src || icon80Wink2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Wink2XPng.src || icon80Wink2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 80, height: 80, srcSet: icon80Wink1XWebp.src || icon80Wink1XWebp },
        { width: 80, height: 80, srcSet: icon80Wink1XPng.src || icon80Wink1XPng },
    ],
};
