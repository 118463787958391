import visaLight3XWebp from './VisaLight@3x.webp';
import visaLight3XPng from './VisaLight@3x.png';
import visaLight2XWebp from './VisaLight@2x.webp';
import visaLight2XPng from './VisaLight@2x.png';
import visaLight1XWebp from './VisaLight@1x.webp';
import visaLight1XPng from './VisaLight@1x.png';
export const visaLightImage = {
    src: visaLight1XPng.src || visaLight1XPng,
    width: 68,
    height: 22,
    sources: [
        {
            width: 68,
            height: 22,
            srcSet: visaLight3XWebp.src || visaLight3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        { width: 68, height: 22, srcSet: visaLight3XPng.src || visaLight3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        {
            width: 68,
            height: 22,
            srcSet: visaLight2XWebp.src || visaLight2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 68, height: 22, srcSet: visaLight2XPng.src || visaLight2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 68, height: 22, srcSet: visaLight1XWebp.src || visaLight1XWebp },
        { width: 68, height: 22, srcSet: visaLight1XPng.src || visaLight1XPng },
    ],
};
