import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Headline, Paragraph } from '@vkontakte/vkui';
import { Icon24CancelOutline } from '@vkontakte/icons';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
import { createAnalyticShowAttribute } from '@common/utils/Analytics';
const WarningBanner = (props) => {
    const { elementKey, theme, withBorder, onClose, icon, content, title, description, footer } = props;
    const className = useMemo(() => classNames(styles.comboWarningBanner, props.className, {
        [styles.comboWarningBanner_withBorder]: withBorder,
        [styles.comboWarningBanner_attention]: theme === 'attention',
        [styles.comboWarningBanner_warning]: theme === 'warning',
        [styles.comboWarningBanner_success]: theme === 'success',
        [styles.comboWarningBanner_info]: theme === 'info',
    }), [theme, withBorder, props.className]);
    return (<>
      <div className={className}>
        {icon}

        <div {...(elementKey ? createAnalyticShowAttribute(elementKey) : undefined)} className={styles.comboWarningBanner__wrapper}>
          {content || (<>
              <Headline Component='div' level='1' weight='2' className={styles.comboWarningBanner__title}>
                <Typograf>{title || ''}</Typograf>
              </Headline>
              <Paragraph Component='div' weight='3' className={styles.comboWarningBanner__description}>
                <Typograf>{description || ''}</Typograf>
              </Paragraph>
            </>)}

          <div className={styles.comboWarningBanner__footer}>{footer}</div>
        </div>

        {typeof onClose === 'function' && (<Icon24CancelOutline onClick={onClose} className={styles.comboWarningBanner__closeIcon}/>)}
      </div>
    </>);
};
export default WarningBanner;
