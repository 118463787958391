import React, { useEffect, useState } from 'react';
import { AdaptivityProvider, AppRoot, ConfigProvider, getViewHeightByViewportHeight, getViewWidthByViewportWidth, Platform, SSRWrapper, ViewWidth, } from '@vkontakte/vkui';
import { useAdaptivity, useAppearance, useInsets } from '@vkontakte/vk-bridge-react';
import apiVkBridge from '@common/utils/ApiVkBridge';
const transformVKBridgeAdaptivity = ({ type, viewportWidth, viewportHeight }) => {
    switch (type) {
        case 'adaptive':
            return {
                viewWidth: getViewWidthByViewportWidth(viewportWidth),
                viewHeight: getViewHeightByViewportHeight(viewportHeight),
            };
        case 'force_mobile':
        case 'force_mobile_compact':
            return {
                viewWidth: ViewWidth.MOBILE,
                sizeX: 'compact',
                sizeY: type === 'force_mobile_compact' ? 'compact' : 'regular',
            };
        default:
            return {};
    }
};
const MiniappVkuiConfigCommon = (props) => {
    const { children } = props;
    const [platform, setPlatform] = useState('vkcom');
    const vkBridgeAppearance = useAppearance() || undefined;
    const vkBridgeInsets = useInsets() || undefined;
    const vkBridgeAdaptivityProps = transformVKBridgeAdaptivity(useAdaptivity());
    useEffect(() => {
        apiVkBridge.callVkBridgeWithTimeout('VKWebAppGetClientVersion', {}).then((data) => {
            switch (data.platform) {
                case 'ios': {
                    setPlatform('ios');
                    break;
                }
                case 'android': {
                    setPlatform('android');
                    break;
                }
                case 'web': {
                    setPlatform('vkcom');
                    break;
                }
                case 'mobile-web': {
                    setPlatform('android');
                    break;
                }
                case 'universal_web': {
                    setPlatform('vkcom');
                    break;
                }
            }
        });
    }, []);
    return (<SSRWrapper>
      <ConfigProvider appearance={vkBridgeAppearance} transitionMotionEnabled={platform === Platform.IOS} isWebView={apiVkBridge.isWebView()} hasCustomPanelHeaderAfter={false} platform={platform}>
        <AdaptivityProvider {...vkBridgeAdaptivityProps}>
          <AppRoot safeAreaInsets={vkBridgeInsets}>{children}</AppRoot>
        </AdaptivityProvider>
      </ConfigProvider>
    </SSRWrapper>);
};
export default MiniappVkuiConfigCommon;
