import React from 'react';
import { CustomImg } from '@common/shared/ui/custom-img';
import { mirDarkImage } from './dark';
import { mirLightImage } from './light';
import { useConfigProvider } from '@vkontakte/vkui';
const Icon = (props) => {
    const { appearance } = useConfigProvider();
    const icon = appearance === 'light' ? mirLightImage : mirDarkImage;
    return (<CustomImg {...icon} {...props} alt={'Иконка платежной системы МИР'}/>);
};
export default Icon;
