import { createEffect } from 'effector/effector.umd';
import waitForResult from '@common/helpers/waitForResult';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { subscriptionsApi } from '@common/entities/subscriptions/api';
import { groupSubscriptionMembersModel } from '@common/features/group-subscription';
const updateSubscriptionDataFx = createEffect(async () => {
    await Promise.all([
        SubscriptionsModel.getSubscriptionsAndSubscriptionsStatusFx({ state: 'updating' }),
        groupSubscriptionMembersModel.getGroupSubscriptionMembersFx(),
    ]);
});
const pullingSubscriptionDataFx = createEffect(() => {
    return waitForResult(subscriptionsApi.getSubscriptionStatus, {
        params: [],
        handleResponse: (params) => {
            const status = params.data.status_subscription_const;
            if (status === 'TRIAL' || status === 'ACTIVE') {
                void updateSubscriptionDataFx();
                return false;
            }
            return true;
        },
        handleError: () => false,
        callInterval: 3000,
    });
});
export const SubscriptionProfileApi = { pullingSubscriptionDataFx, updateSubscriptionDataFx };
