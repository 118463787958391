import { createEffect, createEvent, sample } from 'effector';
import { api } from '../../../api';
import { BANNER_CODE } from '../lib/consts';
export const closeBanner = createEvent();
const closeBannerFx = createEffect(() => {
    api.bannerDisable({ bannerCode: BANNER_CODE });
});
sample({
    clock: closeBanner,
    target: closeBannerFx,
});
