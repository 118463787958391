import { $members } from './members';
import { $getGroupSubscriptionMembersStatus, getGroupSubscriptionMembersFx, GroupSubscriptionMembersGate, } from './getMembers';
import { addMember } from './addMember';
import { changeStateMember } from './changeStateMember';
import { clearMember } from './clearMember';
import { removeMember } from './removeMember';
import { sendMember } from './send-member';
export const groupSubscriptionMembersModel = {
    gate: GroupSubscriptionMembersGate,
    $members,
    sendMember,
    addMember,
    changeStateMember,
    clearMember,
    removeMember,
    getGroupSubscriptionMembersFx,
    $getGroupSubscriptionMembersStatus,
};
