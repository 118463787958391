import React from 'react';
import { CustomImg } from '@common/shared/ui/custom-img';
import { vkPayDarkImage } from './dark';
import { vkPayLightImage } from './light';
import { useConfigProvider } from '@vkontakte/vkui';
const Icon = (props) => {
    const { appearance } = useConfigProvider();
    const icon = appearance === 'light' ? vkPayLightImage : vkPayDarkImage;
    return (<CustomImg {...icon} {...props} alt={'Иконка платежной системы VK pay'}/>);
};
export default Icon;
