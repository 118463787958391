import { mytrackerManager } from '@common/utils/mytracker-manager';
import { ANALYTIC_PLATFORM, ANALYTIC_PLATFORM_SOURCE, ANALYTIC_PLATFORM_TYPE, ANALYTIC_VK_PLATFORM, IS_MINIAPP, SEARCH_VK_PLATFORM, APP_ID, } from '@common/consts';
const getAnalyticContext = async () => {
    const [xMyTrackerApp, xMytrackerLvid] = await Promise.all([
        mytrackerManager.getMyTrackerApp(),
        mytrackerManager.getMyTrackerLvid(),
    ]);
    const nextContext = {
        'X-Mytracker-Lvid': xMytrackerLvid,
        'X-MyTracker-App': xMyTrackerApp,
        platform: ANALYTIC_PLATFORM,
        app_source: ANALYTIC_PLATFORM_SOURCE,
        app_platform: ANALYTIC_PLATFORM_TYPE,
        app_id: APP_ID,
    };
    if (IS_MINIAPP && SEARCH_VK_PLATFORM) {
        return { ...nextContext, [ANALYTIC_VK_PLATFORM]: SEARCH_VK_PLATFORM };
    }
    return nextContext;
};
export default getAnalyticContext;
