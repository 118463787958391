import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    verificationSubscriptionModalHeader__completed: {
        id: 'verificationSubscriptionModalHeader__completed',
        defaultMessage: 'Заявка на подписку одобрена',
    },
    verificationSubscriptionModalSubheader__completed: {
        id: 'verificationSubscriptionModalSubheader__completed',
        defaultMessage: 'Ваш возраст подтверждён. Теперь вы можете подключить молодёжную подписку на VK Музыку.',
    },
    verificationSubscriptionModalHeader__completedIos: {
        id: 'verificationSubscriptionModalHeader__completedIos',
        defaultMessage: 'Заявка на подписку одобрена',
    },
    verificationSubscriptionModalSubheader__completedIos: {
        id: 'verificationSubscriptionModalSubheader__completedIos',
        defaultMessage: 'Теперь вы можете подключить подписку для молодежи в VK Музыке. Обратите внимание, что оплата в приложении недоступна',
    },
    verificationSubscriptionModalHeader__idle: {
        id: 'verificationSubscriptionModalHeader__idle',
        defaultMessage: 'Подтвердите свой возраст',
    },
    verificationSubscriptionModalSubheader__idle: {
        id: 'verificationSubscriptionModalSubheader__idle',
        defaultMessage: 'Подключите Госуслуги или другой сервис к VK ID. Так мы сможем проверить, что ваш возраст подходит под условия подписки.',
    },
    verificationSubscriptionModalHeader__failed: {
        id: 'verificationSubscriptionModalHeader__failed',
        defaultMessage: 'Заявка на подписку отклонена',
    },
    verificationSubscriptionModalSubheader__failed: {
        id: 'verificationSubscriptionModalSubheader__failed',
        defaultMessage: 'Ваш возраст не подходит под условия.',
    },
    verificationSubscriptionModalHeader__failedTrial: {
        id: 'verificationSubscriptionModalHeader__failedTrial',
        defaultMessage: 'Заявка на подписку отклонена',
    },
    verificationSubscriptionModalSubheader__failedTrial: {
        id: 'verificationSubscriptionModalSubheader__failedTrial',
        defaultMessage: 'Ваш возраст не подходит под условия. Попробуйте бесплатную подписку на месяц или подайте заявку как студент.',
    },
    verificationSubscriptionModalHeader__failedPartner: {
        id: 'verificationSubscriptionModalHeader__failedPartner',
        defaultMessage: 'Заявка на подписку отклонена',
    },
    verificationSubscriptionModalSubheader__failedPartner: {
        id: 'verificationSubscriptionModalSubheader__failedPartner',
        defaultMessage: 'Ваш возраст не подходит под условия, но вы можете продолжать слушать музыку без ограничений по текущей подписке',
    },
    verificationSubscriptionModalHeader__errorAge: {
        id: 'verificationSubscriptionModalHeader__errorAge',
        defaultMessage: 'Вы не подходите по возрасту',
    },
    verificationSubscriptionModalSubheader__errorAge: {
        id: 'verificationSubscriptionModalSubheader__errorAge',
        defaultMessage: 'Ваш возраст не подходит под условия, но вы можете продолжать слушать музыку без ограничений по текущей подписке',
    },
    verificationSubscriptionModalHeader__error: {
        id: 'verificationSubscriptionModalHeader__error',
        defaultMessage: 'Не удалось подключить подписку',
    },
    verificationSubscriptionModalSubheader__error: {
        id: 'verificationSubscriptionModalSubheader__error',
        defaultMessage: 'Ваш возраст не подходит под условия или профиль не подтвержден. Пожалуйста, попробуйте оформить подписку еще раз.',
    },
});
