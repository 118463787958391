import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { userDataModel } from '@common/entities/user-data';
import { groupSubscriptionMembersModel } from '@common/features/group-subscription';
import { cancelSubscriptionModel } from '@common/features/cancel-subscription';
import apiCommon from '@common/utils/ApiCommon';
import { useGetSubscriptionDetailsContext } from '@common/entities/subscriptions/lib/modals/show-subscription-details/lib/context';
import { BUTTON_STATE, getButton, SUBSCRIPTION_STATE } from './lib';
import styles from './styles.module.scss';
import { AbTestLkUntieCardModel } from '@common/features/ab-test-lk-untie-card';
import { useGetIsShowNewCancelButtonType } from '@common/entities/subscriptions/lib/modals/show-subscription-details/lib/use-get-is-show-new-cancel-button-type';
import { risingPriceWarningModel, showRisingPriceWarningModal, } from '@common/entities/rising-price-warning';
import { modalManagerModel } from '@common/services/modal-manager';
import { AbTestLkUntieCardV2Model } from '@common/features/ab-test-lk-untie-card-v2/model';
import { useIntl } from 'react-intl';
import messages from './messages';
import { abTestDeactivateTrial, showAbTestDeactivateTrialStopRecurrentWarningModal, } from '@common/features/ab-test-deactivate-trial';
const Button = () => {
    const { subscription } = useGetSubscriptionDetailsContext();
    const intl = useIntl();
    const { userData, closeModal } = useUnit({ userData: userDataModel.$userData, closeModal: modalManagerModel.closeModal });
    const { ownerVkId } = useUnit(groupSubscriptionMembersModel.$members);
    const { cancelButtonSubscriptionType } = useUnit(AbTestLkUntieCardModel.$chosenVariant);
    const updateIsPauseRecurrent = useUnit(AbTestLkUntieCardModel.updateIsPauseRecurrent);
    const getOnStopRecurrentTestVariant = useUnit(abTestDeactivateTrial.stopRecurrent.getOnStopRecurrentTestVariantFx);
    const [isPauseRecurrent, setIsPauseRecurrent] = useState(false);
    const [isPauseAvailable, setIsPauseAvailable] = useState(false);
    const [risingPriceData, setRisingPriceData] = useState({ available: false });
    const getIsShowNewCancelButtonTypeResult = useGetIsShowNewCancelButtonType(cancelButtonSubscriptionType, subscription);
    const [buttonState, setButtonState] = useState(getIsShowNewCancelButtonTypeResult ? BUTTON_STATE.IDLE : BUTTON_STATE.LOADING);
    const { management, status, id } = subscription;
    const isGroupSubscription = subscription?.features?.group?.is_group_subscription;
    const isGroupSubscriptionManagementAvailable = isGroupSubscription && subscription.status !== 'inactive';
    const abTestNeedCustomizeSubscriptionDetail = AbTestLkUntieCardV2Model.useNeedCustomizeSubscriptionDetail();
    const initButton = async () => {
        try {
            await apiCommon
                .getPauseSubscriptionRecurrentStatus()
                .then((response) => {
                setIsPauseRecurrent(response.data.successful ? response.data.data.paused_recurrents : false);
                setIsPauseAvailable(response.data.successful ? response.data.data.pause_available : false);
                updateIsPauseRecurrent(response.data.successful ? response.data.data.paused_recurrents : false);
            })
                .catch();
            await risingPriceWarningModel
                .getRisingPricesData({ type: 'cancel-subscription' })
                .then((response) => {
                setRisingPriceData(response);
            })
                .catch();
        }
        catch {
        }
        finally {
            setButtonState(BUTTON_STATE.IDLE);
        }
    };
    const handleCancelSubscription = async () => {
        setButtonState(BUTTON_STATE.LOADING);
        const mainCancelSubscriptionFlow = async () => {
            if (!risingPriceData.available) {
                await cancelSubscriptionModel.cancelFlow({ subscriptionId: id, isPauseAvailable });
                return;
            }
            showRisingPriceWarningModal({
                analyticShowAttribute: 'modal_subscription_cancel_save_price',
                title: risingPriceData.title,
                detail: risingPriceData.detail,
                firstButton: {
                    analyticClick: 'modal_subscription_cancel_save_price-button_cancel',
                    children: intl.formatMessage(messages.firstButtonText),
                    handler: () => {
                        closeModal();
                    },
                },
                secondButton: {
                    analyticClick: 'modal_subscription_cancel_save_price-button_confirm',
                    children: intl.formatMessage(messages.secondButtonText),
                    handler: async () => {
                        closeModal();
                        await cancelSubscriptionModel.cancelFlow({ subscriptionId: id, isPauseAvailable });
                    },
                },
            });
        };
        getOnStopRecurrentTestVariant()
            .then(async (result) => {
            if (result === 'deactivate') {
                showAbTestDeactivateTrialStopRecurrentWarningModal({ subscriptionId: subscription.id });
                return;
            }
            await mainCancelSubscriptionFlow();
        })
            .catch(mainCancelSubscriptionFlow)
            .finally(() => {
            setButtonState(BUTTON_STATE.IDLE);
        });
    };
    useEffect(() => {
        initButton();
    }, []);
    const mapping = [
        {
            id: SUBSCRIPTION_STATE.LOADING,
            rules: buttonState === BUTTON_STATE.LOADING,
        },
        {
            id: SUBSCRIPTION_STATE.MANAGEMENT,
            rules: Boolean(management),
        },
        {
            id: SUBSCRIPTION_STATE.PAUSE_RECURRENT,
            rules: isPauseRecurrent,
        },
        {
            id: SUBSCRIPTION_STATE.GROUP,
            rules: isGroupSubscriptionManagementAvailable && ownerVkId !== userData.vkId,
        },
    ];
    const value = mapping.find((item) => item.rules)?.id || status;
    const button = getButton({ status: value, handleCancelSubscription });
    if (getIsShowNewCancelButtonTypeResult || abTestNeedCustomizeSubscriptionDetail) {
        return <div>{button}</div>;
    }
    return button ? <div className={styles.buttonContainer}>{button}</div> : null;
};
export default Button;
