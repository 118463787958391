import { createEffect, createEvent, createStore, restore, sample } from 'effector';
import { spread } from 'patronum/spread';
import { IS_MINIAPP } from '@common/consts';
import { StudentModel } from '@common/entities/student/model';
import { StrategyContext, StrategyFactory } from '@common/entities/student/lib';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { studentOfferModel } from '@common/features/start-student-flow/model/student-offer';
import { strategyMap } from '@apps/common/entities/student/lib/strategy/strategy-factory';
import { youngSubscriptionModel } from '@apps/common/entities/young-subscription';
import { getFeatureFlag } from '@common/shared/lib/feature-flags';
import { createBeforeStartFlowFx } from './before-start-flow';
import { changeApplyYoungButtonLoadingState } from '@website/template/young-template/ui/apply-young-button/model/apply-young-button-loading-state';
const beforeStartFlowFx = createBeforeStartFlowFx();
const startFlow = createEvent();
const $skipYoungFlow = createStore(false);
$skipYoungFlow.on(startFlow, (_state, payload) => !!payload?.skipYoungFlow);
const $callbacksFromEvent = restore(startFlow, {
    callbackAfterFinishedFlow: () => Promise.resolve(),
    strategyCallback: () => Promise.resolve(),
});
const $startFlowEventWasCalledParams = createStore(null);
const finishedFx = createEffect((callback) => {
    callback && void callback();
});
const getFlowInDependenceOnStudentStatusFx = createEffect(async ({ offers, studentStatusData, callback, activeSubscription }) => {
    if (!studentStatusData) {
        return;
    }
    const skipYoungFlow = $skipYoungFlow.getState();
    const strategyFactory = new StrategyFactory();
    const strategy = strategyFactory.getStrategy(studentStatusData.status);
    const context = new StrategyContext(strategy);
    const doStrategy = async () => await context.doStrategy({
        studentOffer: offers,
        statusData: studentStatusData,
        callback,
        activeSubscription,
    });
    const enableYoungSubscription = await getFeatureFlag('enable_young_subscription');
    if (strategy !== strategyMap['CONFIRMED'] && enableYoungSubscription && !skipYoungFlow) {
        await youngSubscriptionModel.startYoungSubscriptionFlow({
            studentFlowCb: doStrategy,
        });
        changeApplyYoungButtonLoadingState('idle');
        return;
    }
    await doStrategy();
    changeApplyYoungButtonLoadingState('idle');
});
if (!IS_MINIAPP) {
    sample({
        clock: startFlow,
        source: {
            subscriptionsState: SubscriptionsModel.$subscriptionsState,
        },
        filter: ({ subscriptionsState }) => subscriptionsState === 'initial',
        fn: () => ({ state: 'updating' }),
        target: SubscriptionsModel.getSubscriptionsFx,
    });
}
sample({
    clock: startFlow,
    source: {
        subscriptionsState: SubscriptionsModel.$subscriptionsState,
    },
    filter: ({ subscriptionsState }) => !(subscriptionsState === 'success' || subscriptionsState === 'error'),
    fn: (_, startFlowParams) => startFlowParams,
    target: $startFlowEventWasCalledParams,
});
sample({
    clock: SubscriptionsModel.$subscriptionsState,
    source: {
        startFlowEventWasCalledParams: $startFlowEventWasCalledParams,
    },
    filter: ({ startFlowEventWasCalledParams }, subscriptionsState) => Boolean(startFlowEventWasCalledParams) && (subscriptionsState === 'success' || subscriptionsState === 'error'),
    fn: ({ startFlowEventWasCalledParams }) => ({
        startFlow: startFlowEventWasCalledParams ?? {},
        startFlowEventWasCalledParams: null,
    }),
    target: spread({
        targets: {
            startFlow,
            startFlowEventWasCalledParams: $startFlowEventWasCalledParams,
        },
    }),
});
sample({
    clock: startFlow,
    source: { studentStatusData: StudentModel.$sourceData, subscriptionsState: SubscriptionsModel.$subscriptionsState },
    filter: (sourceData) => {
        return sourceData.subscriptionsState === 'success' || sourceData.subscriptionsState === 'error';
    },
    fn: (_, clockData) => ({
        startFlowParams: {
            studentOffer: clockData.studentOffer,
        },
        offersParams: clockData.studentOffer,
    }),
    target: spread({ targets: { startFlowParams: beforeStartFlowFx, offersParams: studentOfferModel.$studentOffer } }),
});
sample({
    clock: beforeStartFlowFx.doneData,
    source: {
        offers: studentOfferModel.$studentOffer,
        studentStatusData: StudentModel.$sourceData,
        callback: $callbacksFromEvent,
        activeSubscription: SubscriptionsModel.$activeSubscription,
    },
    fn: (sourceData) => ({ ...sourceData, callback: sourceData.callback.strategyCallback }),
    target: getFlowInDependenceOnStudentStatusFx,
});
sample({
    clock: getFlowInDependenceOnStudentStatusFx.done,
    source: { callback: $callbacksFromEvent },
    fn: (source) => source.callback.callbackAfterFinishedFlow,
    target: finishedFx,
});
export const studentFlowModel = {
    startFlow,
};
