import { OFFERS_KEY_WORD_NEXT_WEBSITE, OFFERS_KEY_WORD_RESTORE_WEBSITE, OFFERS_KEY_WORD_STUDENT_NOT_AVAILABLE_WEBSITE, OFFERS_KEY_WORD_STUDENT_WEBSITE, OFFERS_KEY_WORD_WEBSITE_CANCEL_GROUP_SUBSCRIPTION_SUCCESS, OFFERS_KEY_WORD_WEBSITE_LEFT_GROUP_SUBSCRIPTION_SUCCESS, OFFERS_KEY_WORD_WEBSITE_YOUNG, OFFERS_KEY_WORD_WEBSITE_YOUNG_NOT_AVAILABLE, } from '@website/shared/constants/website-keywords';
export const KEYWORDS_FOR_KEYWORDS_KEEPER = {
    subscriptionDetails: OFFERS_KEY_WORD_RESTORE_WEBSITE,
    studentsRejected: OFFERS_KEY_WORD_STUDENT_NOT_AVAILABLE_WEBSITE,
    studentStatusInfoBannerKeyword: OFFERS_KEY_WORD_STUDENT_WEBSITE,
    leaveGroupSubscriptionSuccessKeyword: OFFERS_KEY_WORD_WEBSITE_LEFT_GROUP_SUBSCRIPTION_SUCCESS,
    cancelGroupSubscriptionSuccessKeyword: OFFERS_KEY_WORD_WEBSITE_CANCEL_GROUP_SUBSCRIPTION_SUCCESS,
    profileAvailableKeyword: OFFERS_KEY_WORD_NEXT_WEBSITE,
    profileNotAvailableKeyword: OFFERS_KEY_WORD_NEXT_WEBSITE,
    youngSubscription: OFFERS_KEY_WORD_WEBSITE_YOUNG,
    youngRejected: OFFERS_KEY_WORD_WEBSITE_YOUNG_NOT_AVAILABLE,
};
