import visaDark3XWebp from './VisaDark@3x.webp';
import visaDark3XPng from './VisaDark@3x.png';
import visaDark2XWebp from './VisaDark@2x.webp';
import visaDark2XPng from './VisaDark@2x.png';
import visaDark1XWebp from './VisaDark@1x.webp';
import visaDark1XPng from './VisaDark@1x.png';
export const visaDarkImage = {
    src: visaDark1XPng.src || visaDark1XPng,
    width: 68,
    height: 22,
    sources: [
        { width: 68, height: 22, srcSet: visaDark3XWebp.src || visaDark3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 68, height: 22, srcSet: visaDark3XPng.src || visaDark3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 68, height: 22, srcSet: visaDark2XWebp.src || visaDark2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 68, height: 22, srcSet: visaDark2XPng.src || visaDark2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 68, height: 22, srcSet: visaDark1XWebp.src || visaDark1XWebp },
        { width: 68, height: 22, srcSet: visaDark1XPng.src || visaDark1XPng },
    ],
};
