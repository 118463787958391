import React from 'react';
import { Card } from '@vkontakte/vkui';
import { Icon48Education, Icon48ForFour, Icon48ForTwo, Icon48Music, Icon48Music12, Icon48Wink, } from '@common/shared/ui/icons/48';
import styles from './styles.module.scss';
import { Icon24ChevronCompactRight } from '@vkontakte/icons';
const ICON_MAP = {
    vkmusic: <Icon48Music />,
    yearly_vkmusic: <Icon48Music12 />,
    student: <Icon48Education />,
    vkmusic_wink: <Icon48Wink />,
    duo_vkmusic: <Icon48ForTwo />,
    quatro_vkmusic: <Icon48ForFour />,
};
const getIconMap = (value) => {
    if (!value) {
        return null;
    }
    return ICON_MAP[value] ?? null;
};
const SubscriptionCardQ32024 = (props) => {
    const { subscription_type, additional_info, type, slot, onClick } = props;
    const icon = getIconMap(type);
    return (<Card Component={'li'} className={styles.subscriptionCardQ32024}>
      <div className={styles.subscriptionBlock} onClick={onClick} data-onclick={Boolean(onClick)}>
        <div className={styles.subscriptionBlock__content}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.content}>
            <span className={styles.title}>{subscription_type}</span>
            <span className={styles.caption}>{additional_info}</span>
          </div>
        </div>
        {onClick && (<div className={styles.chevron}>
            <Icon24ChevronCompactRight fill={'var(--vkui--color_icon_tertiary)'}/>
          </div>)}
      </div>
      {slot}
    </Card>);
};
export default SubscriptionCardQ32024;
