import icon80Music3XWebp from './icon-80-music@3x.webp';
import icon80Music3XPng from './icon-80-music@3x.png';
import icon80Music2XWebp from './icon-80-music@2x.webp';
import icon80Music2XPng from './icon-80-music@2x.png';
import icon80Music1XWebp from './icon-80-music@1x.webp';
import icon80Music1XPng from './icon-80-music@1x.png';
export const icon80MusicImage = {
    src: icon80Music1XPng.src || icon80Music1XPng,
    width: 80,
    height: 80,
    sources: [
        {
            width: 80,
            height: 80,
            srcSet: icon80Music3XWebp.src || icon80Music3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Music3XPng.src || icon80Music3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Music2XWebp.src || icon80Music2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Music2XPng.src || icon80Music2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 80, height: 80, srcSet: icon80Music1XWebp.src || icon80Music1XWebp },
        { width: 80, height: 80, srcSet: icon80Music1XPng.src || icon80Music1XPng },
    ],
};
