import mastercardDark3XWebp from './MastercardDark@3x.webp';
import mastercardDark3XPng from './MastercardDark@3x.png';
import mastercardDark2XWebp from './MastercardDark@2x.webp';
import mastercardDark2XPng from './MastercardDark@2x.png';
import mastercardDark1XWebp from './MastercardDark@1x.webp';
import mastercardDark1XPng from './MastercardDark@1x.png';
export const mastercardDarkImage = {
    src: mastercardDark1XPng.src || mastercardDark1XPng,
    width: 49,
    height: 30,
    sources: [
        {
            width: 49,
            height: 30,
            srcSet: mastercardDark3XWebp.src || mastercardDark3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 49,
            height: 30,
            srcSet: mastercardDark3XPng.src || mastercardDark3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 49,
            height: 30,
            srcSet: mastercardDark2XWebp.src || mastercardDark2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 49,
            height: 30,
            srcSet: mastercardDark2XPng.src || mastercardDark2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 49, height: 30, srcSet: mastercardDark1XWebp.src || mastercardDark1XWebp },
        { width: 49, height: 30, srcSet: mastercardDark1XPng.src || mastercardDark1XPng },
    ],
};
