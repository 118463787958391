import apiCommon from '@common/utils/ApiCommon';
const getWinbackCheck = () => apiCommon
    .makeGetRequest(`/api/front/user/winback/check`, undefined)
    .then((res) => res.data);
const getWinbackAb0325 = () => apiCommon
    .makeGetRequest(`/api/front/user/ab/winback0325-variant`, undefined)
    .then((res) => {
    if (!res.data.successful) {
        return Promise.reject();
    }
    return res.data.data.variant;
});
export const api = {
    getWinbackCheck,
    getWinbackAb0325,
};
