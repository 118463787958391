import { attach, createEvent, sample } from 'effector';
import { combineEvents } from 'patronum/combine-events';
import { SubscriptionsModel } from '../../subscriptions/model';
import { getYoungSubscriptionData, getYoungSubscriptionDataFx } from './get-young-subscription-data';
import { $verificationStatus } from './verification-status';
import { youngSubscriptionFlow } from './young-subscription-flow';
export const startYoungVerificationFlow = createEvent();
sample({
    clock: startYoungVerificationFlow,
    target: getYoungSubscriptionData,
});
const startYoungVerificationFlowFx = attach({
    source: {
        verificationStatus: $verificationStatus,
    },
    effect: ({ verificationStatus }) => {
        if (verificationStatus === 'idle') {
            return;
        }
        youngSubscriptionFlow();
    },
});
sample({
    clock: combineEvents([
        startYoungVerificationFlow,
        getYoungSubscriptionDataFx.done,
        SubscriptionsModel.getSubscriptionsFx.done,
    ]),
    target: startYoungVerificationFlowFx,
});
