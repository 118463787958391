import React, { useState } from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import { intl } from '@common/shared/lib/intl';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary } from '../../../../../lib/analytics-dictionary';
import { showBenefitsCancelSubscription } from '../../messages';
const BenefitsCancelSubscriptionActions = (props) => {
    const { cancelSubscriptionHandler, cancelButtonText } = props;
    const [isLoading, setIsLoading] = useState(false);
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCancelSubscriptionBenefitsButtonCancelClick)} size={'l'} stretched={true} disabled={isLoading} onClick={() => modalManagerModel.closeModal()}>
        {intl.formatMessage(showBenefitsCancelSubscription.cancelButton)}
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCancelSubscriptionBenefitsButtonConfirmClick)} size={'l'} stretched={true} mode={'secondary'} loading={isLoading} disabled={isLoading} onClick={async () => {
            setIsLoading(true);
            await cancelSubscriptionHandler();
            setIsLoading(false);
        }}>
        {cancelButtonText || intl.formatMessage(showBenefitsCancelSubscription.confirmButton)}
      </ModalButton>
    </ButtonGroup>);
};
export default BenefitsCancelSubscriptionActions;
