import { IS_MINIAPP } from '@apps/common/consts';
import { KeywordsKeeper } from '@common/entities/offers';
import apiCommon from '@common/utils/ApiCommon';
import { VK_VERIFICATIONS_URL_WEBSITE, VK_VERIFICATIONS_URL_MINIAPP } from '../lib/consts';
export const redirectToVkVerifications = () => {
    if (!window) {
        return;
    }
    window.open(IS_MINIAPP ? VK_VERIFICATIONS_URL_MINIAPP : VK_VERIFICATIONS_URL_WEBSITE, '_blank');
};
export const checkIsIos = async () => {
    if (IS_MINIAPP) {
        const { platform } = await (await import('@common/utils/ApiVkBridge')).default.getClientVersion();
        return platform === 'ios';
    }
    return false;
};
export const getOffer = async (params) => {
    const { offerKey } = params;
    const offersKey = await KeywordsKeeper.getKeywordsByDictionaryKey(offerKey);
    const offers = await apiCommon.getOffers({ keywords: [offersKey], useUserCountry: true });
    if (!offers.data.success) {
        return Promise.reject();
    }
    if (offers.data.payload.length === 0) {
        return Promise.reject();
    }
    return offers.data.payload[0];
};
