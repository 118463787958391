import { memberStatusMapping } from './member-status-mapping';
export const createMemberStatusMapping = (data) => {
    const mapping = {
        ...memberStatusMapping,
        owner: data?.badge ?? memberStatusMapping.owner,
    };
    const getMemberStatusContent = (status) => {
        return mapping[status];
    };
    return { getMemberStatusContent };
};
