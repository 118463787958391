import { startYoungSubscriptionFlow } from './start-young-subscription-flow';
import { startYoungVerificationFlow } from './start-young-verification-flow';
import { youngSubscriptionFlow } from './young-subscription-flow';
import { getYoungSubscriptionData, getYoungSubscriptionDataFx } from './get-young-subscription-data';
export const youngSubscriptionModel = {
    startYoungSubscriptionFlow,
    startYoungVerificationFlow,
    youngSubscriptionFlow,
    getYoungSubscriptionData,
    getYoungSubscriptionDataFx,
};
