import { createEffect, createEvent, createStore, sample } from 'effector';
import { getOffer } from '../../../model/actions';
export const getOfferYoungInfoBanner = createEvent();
const getOfferFx = createEffect(async () => {
    const offerKey = 'youngSubscription';
    const offer = await getOffer({ offerKey });
    return offer;
});
export const $offerYoungInfoBanner = createStore(null).on(getOfferFx.doneData, (_, offer) => offer ?? null);
sample({
    clock: getOfferYoungInfoBanner,
    target: getOfferFx,
});
