import { intl } from '@apps/common/shared/lib/intl';
import { messages } from '../lib/messages';
import { closeBanner } from './close-banner';
export const contentMap = {
    completed: {
        title: intl.formatMessage(messages.youngStatusInfoBannerTitle__completed),
        description: intl.formatMessage(messages.youngStatusInfoBannerDescription__completed),
        onClose: closeBanner,
        theme: 'success',
    },
    process: {
        theme: 'info',
        title: intl.formatMessage(messages.youngStatusInfoBannerTitle__process),
        description: intl.formatMessage(messages.youngStatusInfoBannerDescription__process),
    },
    failed: {
        theme: 'warning',
        title: intl.formatMessage(messages.youngStatusInfoBannerTitle__failed),
        description: intl.formatMessage(messages.youngStatusInfoBannerDescription__failed),
        onClose: closeBanner,
    },
};
