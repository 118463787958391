import unionLight3XWebp from './UnionLight@3x.webp';
import unionLight3XPng from './UnionLight@3x.png';
import unionLight2XWebp from './UnionLight@2x.webp';
import unionLight2XPng from './UnionLight@2x.png';
import unionLight1XWebp from './UnionLight@1x.webp';
import unionLight1XPng from './UnionLight@1x.png';
export const unionLightImage = {
    src: unionLight1XPng.src || unionLight1XPng,
    width: 49,
    height: 30,
    sources: [
        {
            width: 49,
            height: 30,
            srcSet: unionLight3XWebp.src || unionLight3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 49,
            height: 30,
            srcSet: unionLight3XPng.src || unionLight3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 49,
            height: 30,
            srcSet: unionLight2XWebp.src || unionLight2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 49,
            height: 30,
            srcSet: unionLight2XPng.src || unionLight2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 49, height: 30, srcSet: unionLight1XWebp.src || unionLight1XWebp },
        { width: 49, height: 30, srcSet: unionLight1XPng.src || unionLight1XPng },
    ],
};
