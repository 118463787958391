import icon80Music123XWebp from './icon-80-music-12@3x.webp';
import icon80Music123XPng from './icon-80-music-12@3x.png';
import icon80Music122XWebp from './icon-80-music-12@2x.webp';
import icon80Music122XPng from './icon-80-music-12@2x.png';
import icon80Music121XWebp from './icon-80-music-12@1x.webp';
import icon80Music121XPng from './icon-80-music-12@1x.png';
export const icon80Music12Image = {
    src: icon80Music121XPng.src || icon80Music121XPng,
    width: 80,
    height: 80,
    sources: [
        {
            width: 80,
            height: 80,
            srcSet: icon80Music123XWebp.src || icon80Music123XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Music123XPng.src || icon80Music123XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Music122XWebp.src || icon80Music122XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80Music122XPng.src || icon80Music122XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 80, height: 80, srcSet: icon80Music121XWebp.src || icon80Music121XWebp },
        { width: 80, height: 80, srcSet: icon80Music121XPng.src || icon80Music121XPng },
    ],
};
