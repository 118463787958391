import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RawIntlProvider } from 'react-intl';
import { callReceivedOffersServiceError, callReceivedPaymentsServiceError, useAnalyticGlobalClick, useAnalyticGlobalShow, } from '@analytics';
import { attachReduxDevTools } from '@effector/redux-devtools-adapter';
import { SplitLayout } from '@vkontakte/vkui';
import { useUnit } from 'effector-react';
import i18n from '@common/shared/lib/i18next';
import { intl } from '@common/shared/lib/intl';
import { IS_DEVELOPMENT } from '@common/consts';
import { initInterceptors, showDDOSModal, showMaintainModal } from '@common/application/configuration/interceptors';
import apiCommon from '@common/utils/ApiCommon';
import { AuthStateModel } from '@common/entities/auth-state';
import { SnackbarManager } from '@common/services/snackbar-manager';
import { alertManagerModel } from '@common/entities/alert-manager';
import { RootModals } from '@common/widgets/root/root-modals';
import { appEvents } from '@common/utils/apps-events';
import { vkPixel } from '@common/utils/VkPixel';
import { mytrackerManager } from '@common/utils/mytracker-manager';
import '@common/assets/scss/global.scss';
import '@common/application/configuration/dayjs';
import '@vkontakte/vkui/dist/vkui.css';
import '@common/shared/lib/set-release-version';
import styles from './styles.module.scss';
initInterceptors([showDDOSModal, showMaintainModal]);
apiCommon.addListener('offersServiceError', () => {
    const is_logged_in = AuthStateModel.$isAuthorized.getState();
    callReceivedOffersServiceError({ is_logged_in });
});
apiCommon.addListener('paymentsServiceError', () => {
    const is_logged_in = AuthStateModel.$isAuthorized.getState();
    callReceivedPaymentsServiceError({ is_logged_in });
});
appEvents.addEventListener('subscriptionActivated', () => {
    vkPixel.sendSubscriptionGoalReached();
    mytrackerManager.eventSubscriptionActivated();
});
const ApplicationConfigCommon = (props) => {
    const { children, header } = props;
    const alert = useUnit(alertManagerModel.$alert);
    useAnalyticGlobalClick();
    useAnalyticGlobalShow();
    useEffect(() => {
        try {
            IS_DEVELOPMENT &&
                attachReduxDevTools({
                    name: process.env.NEXT_PUBLIC_ANALYTIC_PLATFORM_SOURCE ?? 'vkmusic',
                    trace: true,
                });
        }
        catch (e) {
        }
    }, []);
    return (<I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <RawIntlProvider value={intl}>
        <SplitLayout modal={<RootModals />} popout={alert} header={header} className={styles.applicationConfigCommon}>
          {children}
          <SnackbarManager />
        </SplitLayout>
      </RawIntlProvider>
    </I18nextProvider>);
};
export default ApplicationConfigCommon;
