import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    youngStatusInfoBannerTitle__completed: {
        id: 'youngStatusInfoBannerTitle__completed',
        defaultMessage: 'Верификация пройдена',
    },
    youngStatusInfoBannerDescription__completed: {
        id: 'youngStatusInfoBannerDescription__completed',
        defaultMessage: 'Мы получили информацию о вашем возрасте.',
    },
    youngStatusInfoBannerTitle__process: {
        id: 'youngStatusInfoBannerTitle__process',
        defaultMessage: 'На рассмотрении...',
    },
    youngStatusInfoBannerDescription__process: {
        id: 'youngStatusInfoBannerDescription__process',
        defaultMessage: 'Проверка может занимать до 48 часов',
    },
    youngStatusInfoBannerTitle__failed: {
        id: 'youngStatusInfoBannerTitle__failed',
        defaultMessage: 'Верификация не пройдена',
    },
    youngStatusInfoBannerDescription__failed: {
        id: 'youngStatusInfoBannerDescription__failed',
        defaultMessage: 'Вы не подходите по возрастному ограничению подписку',
    },
});
