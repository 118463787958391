import icon80ForFour3XWebp from './icon-80-for-four@3x.webp';
import icon80ForFour3XPng from './icon-80-for-four@3x.png';
import icon80ForFour2XWebp from './icon-80-for-four@2x.webp';
import icon80ForFour2XPng from './icon-80-for-four@2x.png';
import icon80ForFour1XWebp from './icon-80-for-four@1x.webp';
import icon80ForFour1XPng from './icon-80-for-four@1x.png';
export const icon80ForFourImage = {
    src: icon80ForFour1XPng.src || icon80ForFour1XPng,
    width: 80,
    height: 80,
    sources: [
        {
            width: 80,
            height: 80,
            srcSet: icon80ForFour3XWebp.src || icon80ForFour3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80ForFour3XPng.src || icon80ForFour3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80ForFour2XWebp.src || icon80ForFour2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 80,
            height: 80,
            srcSet: icon80ForFour2XPng.src || icon80ForFour2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 80, height: 80, srcSet: icon80ForFour1XWebp.src || icon80ForFour1XWebp },
        { width: 80, height: 80, srcSet: icon80ForFour1XPng.src || icon80ForFour1XPng },
    ],
};
