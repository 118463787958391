import jcbDark3XWebp from './JCBDark@3x.webp';
import jcbDark3XPng from './JCBDark@3x.png';
import jcbDark2XWebp from './JCBDark@2x.webp';
import jcbDark2XPng from './JCBDark@2x.png';
import jcbDark1XWebp from './JCBDark@1x.webp';
import jcbDark1XPng from './JCBDark@1x.png';
export const jcbDarkImage = {
    src: jcbDark1XPng.src || jcbDark1XPng,
    width: 40,
    height: 30,
    sources: [
        { width: 40, height: 30, srcSet: jcbDark3XWebp.src || jcbDark3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 40, height: 30, srcSet: jcbDark3XPng.src || jcbDark3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 40, height: 30, srcSet: jcbDark2XWebp.src || jcbDark2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 40, height: 30, srcSet: jcbDark2XPng.src || jcbDark2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 40, height: 30, srcSet: jcbDark1XWebp.src || jcbDark1XWebp },
        { width: 40, height: 30, srcSet: jcbDark1XPng.src || jcbDark1XPng },
    ],
};
