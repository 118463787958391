import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions/lib/modals';
import { showBenefitsCancelSubscription } from '../lib/modals';
import { suspendFlow } from './suspend-flow';
import { winbackFlow } from './winback-flow';
export const cancelBaseFlow = async (params) => {
    const { subscriptionId, isPauseAvailable } = params;
    const cancelSubscription = async () => {
        await winbackFlow({
            subscriptionId: subscriptionId,
        });
    };
    if (isPauseAvailable) {
        suspendFlow({ subscriptionId, cancelSubscription });
        return;
    }
    showBenefitsCancelSubscription({
        cancelSubscriptionHandler: () => {
            showSubscriptionCancelReasonModal({ subscriptionId });
        },
    });
};
