import icon3XWebp from './icon@3x.webp';
import icon3XPng from './icon@3x.png';
import icon2XWebp from './icon@2x.webp';
import icon2XPng from './icon@2x.png';
import icon1XWebp from './icon@1x.webp';
import icon1XPng from './icon@1x.png';
export const iconImage = {
    src: icon1XPng.src || icon1XPng,
    width: 48,
    height: 48,
    sources: [
        { width: 48, height: 48, srcSet: icon3XWebp.src || icon3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 48, height: 48, srcSet: icon3XPng.src || icon3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 48, height: 48, srcSet: icon2XWebp.src || icon2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 48, height: 48, srcSet: icon2XPng.src || icon2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 48, height: 48, srcSet: icon1XWebp.src || icon1XWebp },
        { width: 48, height: 48, srcSet: icon1XPng.src || icon1XPng },
    ],
};
