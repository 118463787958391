import unionDark3XWebp from './UnionDark@3x.webp';
import unionDark3XPng from './UnionDark@3x.png';
import unionDark2XWebp from './UnionDark@2x.webp';
import unionDark2XPng from './UnionDark@2x.png';
import unionDark1XWebp from './UnionDark@1x.webp';
import unionDark1XPng from './UnionDark@1x.png';
export const unionDarkImage = {
    src: unionDark1XPng.src || unionDark1XPng,
    width: 49,
    height: 30,
    sources: [
        {
            width: 49,
            height: 30,
            srcSet: unionDark3XWebp.src || unionDark3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        { width: 49, height: 30, srcSet: unionDark3XPng.src || unionDark3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        {
            width: 49,
            height: 30,
            srcSet: unionDark2XWebp.src || unionDark2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 49, height: 30, srcSet: unionDark2XPng.src || unionDark2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 49, height: 30, srcSet: unionDark1XWebp.src || unionDark1XWebp },
        { width: 49, height: 30, srcSet: unionDark1XPng.src || unionDark1XPng },
    ],
};
