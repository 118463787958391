import jcbLight3XWebp from './JCBLight@3x.webp';
import jcbLight3XPng from './JCBLight@3x.png';
import jcbLight2XWebp from './JCBLight@2x.webp';
import jcbLight2XPng from './JCBLight@2x.png';
import jcbLight1XWebp from './JCBLight@1x.webp';
import jcbLight1XPng from './JCBLight@1x.png';
export const jcbLightImage = {
    src: jcbLight1XPng.src || jcbLight1XPng,
    width: 40,
    height: 30,
    sources: [
        { width: 40, height: 30, srcSet: jcbLight3XWebp.src || jcbLight3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 40, height: 30, srcSet: jcbLight3XPng.src || jcbLight3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 40, height: 30, srcSet: jcbLight2XWebp.src || jcbLight2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 40, height: 30, srcSet: jcbLight2XPng.src || jcbLight2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 40, height: 30, srcSet: jcbLight1XWebp.src || jcbLight1XWebp },
        { width: 40, height: 30, srcSet: jcbLight1XPng.src || jcbLight1XPng },
    ],
};
