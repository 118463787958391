import { attach } from 'effector';
import { applyOffer } from '@apps/common/components/Root/actions';
import { IS_MINIAPP } from '@apps/common/consts';
import apiVkBridge from '@apps/common/utils/ApiVkBridge';
import { findMetric } from '@apps/common/utils/Analytics/dictionary';
import { closeBanner } from '../../../../model/close-banner';
import { changeButtonLoading } from '../button-loading';
import { $offerYoungInfoBanner } from '../../../../model/offer-data';
export const iosButtonData = {
    analyticClick: 'young-status-info-banner_ios-button',
    handler: closeBanner,
    mode: 'primary',
    children: 'Понятно',
};
export const getButtonDataFx = attach({
    source: {
        offer: $offerYoungInfoBanner,
    },
    effect: async ({ offer }) => {
        if (IS_MINIAPP && (await apiVkBridge.getClientVersion()).platform === 'ios') {
            return iosButtonData;
        }
        const correlationId = offer?.correlationId || '';
        const widget = offer?.widgets.trial || offer?.widgets.offer;
        const button = widget?.buttons[0] || { type: 'purchase' };
        const analyticClick = findMetric(widget?.buttons[0]?.metrics, 'click')?.value || 'reject-modal_verification_subscription-button';
        const applyOfferHandler = async () => {
            changeButtonLoading(true);
            await applyOffer({
                correlationId,
                button,
            });
            changeButtonLoading(false);
        };
        return {
            analyticClick,
            handler: applyOfferHandler,
            mode: 'primary',
            children: widget?.buttons[0]?.name || '',
        };
    },
});
