import React from 'react';
import { CustomImg } from '@common/shared/ui/custom-img';
import { jcbDarkImage } from './dark';
import { jcbLightImage } from './light';
import { useConfigProvider } from '@vkontakte/vkui';
const Icon = (props) => {
    const { appearance } = useConfigProvider();
    const icon = appearance === 'light' ? jcbLightImage : jcbDarkImage;
    return (<CustomImg {...icon} {...props} alt={'Иконка платежной системы JCB'}/>);
};
export default Icon;
