import { createEffect, createEvent, sample } from 'effector';
import { api } from '../api';
export const getYoungSubscriptionData = createEvent();
export const getYoungSubscriptionDataFx = createEffect(async () => {
    try {
        const response = await api.getYouthSubscriptionData();
        if (!response.success) {
            return { status: 'error', showBanner: false };
        }
        const getStatus = () => {
            switch (response.data?.status) {
                case 0: {
                    return 'idle';
                }
                case 1: {
                    return 'completed';
                }
                case 2: {
                    return 'failed';
                }
                default: {
                    return 'error';
                }
            }
        };
        return { status: getStatus(), showBanner: response.data.showBanner };
    }
    catch {
        return { status: 'error', showBanner: false };
    }
});
sample({ clock: getYoungSubscriptionData, target: getYoungSubscriptionDataFx });
