import React from 'react';
import { Avatar } from '@vkontakte/vkui';
import { Icon24BlockOutline, Icon24RecentOutline } from '@vkontakte/icons';
import styles from './styles.module.scss';
const MembersListAvatarOverlay = (props) => {
    const { type } = props;
    const icon = {
        invitation_sent: <Icon24RecentOutline />,
        invitation_rejected: <Icon24BlockOutline />,
    };
    return (<Avatar.Overlay className={styles.overlay} theme={'dark'} visibility={'always'}>
      {icon[type]}
    </Avatar.Overlay>);
};
export default MembersListAvatarOverlay;
