import { IS_MINIAPP } from '@common/consts';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions/lib/modals';
import { $offersSuccess, getOffersFx } from '@common/features/cancel-subscription/model/offers';
import { showWinbackCancelSubscription, showBenefitsCancelSubscription } from '../lib/modals';
const showVariantC = (params) => {
    const { subscriptionId } = params;
    showBenefitsCancelSubscription({
        cancelSubscriptionHandler: async () => {
            showSubscriptionCancelReasonModal({ subscriptionId });
        },
    });
};
const showWinbackCancelSubscriptionHandler = async (params) => {
    const { subscriptionId, keyword } = params;
    await getOffersFx({ keywords: [keyword] });
    if (!$offersSuccess.getState()?.correlationId) {
        showSubscriptionCancelReasonModal({ subscriptionId });
        return;
    }
    showWinbackCancelSubscription({ ...params, useCardForm: true });
};
export const winback0325Flow = async (params) => {
    const { subscriptionId, variantAb } = params;
    const platform = IS_MINIAPP ? 'miniapp' : 'website';
    switch (variantAb) {
        case 'c': {
            showVariantC(params);
            return;
        }
        case 't1': {
            await showWinbackCancelSubscriptionHandler({ ...params, keyword: `winback_${platform}_0325_t1`, typeIcon: 'ab' });
            return;
        }
        case 't2': {
            await showWinbackCancelSubscriptionHandler({ ...params, keyword: `winback_${platform}_0325_t2`, typeIcon: 'ab' });
            return;
        }
        default: {
            showSubscriptionCancelReasonModal({ subscriptionId });
        }
    }
};
