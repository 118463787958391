import img3XWebp from './IMG@3x.webp';
import img3XPng from './IMG@3x.png';
import img2XWebp from './IMG@2x.webp';
import img2XPng from './IMG@2x.png';
import img1XWebp from './IMG@1x.webp';
import img1XPng from './IMG@1x.png';
export const imgImage = {
    src: img1XPng.src || img1XPng,
    width: 52,
    height: 36,
    sources: [
        { width: 52, height: 36, srcSet: img3XWebp.src || img3XWebp, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 52, height: 36, srcSet: img3XPng.src || img3XPng, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 52, height: 36, srcSet: img2XWebp.src || img2XWebp, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 52, height: 36, srcSet: img2XPng.src || img2XPng, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 52, height: 36, srcSet: img1XWebp.src || img1XWebp },
        { width: 52, height: 36, srcSet: img1XPng.src || img1XPng },
    ],
};
