import { createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { api } from '../../api';
import { mappings } from '../../lib/mappings';
import { $members } from './members';
import { spread } from 'patronum';
export const GroupSubscriptionMembersGate = createGate({});
export const $getGroupSubscriptionMembersStatus = createStore('initial');
export const getGroupSubscriptionMembersFx = createEffect(async () => await api.getGroupSubscriptionMembers());
$members.on(getGroupSubscriptionMembersFx.doneData, (state, payload) => {
    if (!payload.successful) {
        return state;
    }
    const { id, capacity, members, owner_user_id } = payload.data;
    const mapMembers = members.map((member) => mappings.apiMemberGroupSubscription(member));
    return {
        groupId: id,
        capacity,
        ownerVkId: owner_user_id,
        items: mapMembers,
    };
});
sample({
    clock: GroupSubscriptionMembersGate.open,
    source: { $members },
    filter: ({ $members }) => !$members.items.length,
    target: getGroupSubscriptionMembersFx,
});
sample({
    clock: getGroupSubscriptionMembersFx.doneData,
    fn: () => ({ status: 'success' }),
    target: spread({ targets: { status: $getGroupSubscriptionMembersStatus } }),
});
sample({
    clock: getGroupSubscriptionMembersFx.failData,
    fn: () => ({ status: 'error' }),
    target: spread({ targets: { status: $getGroupSubscriptionMembersStatus } }),
});
