import mastercardLight3XWebp from './MastercardLight@3x.webp';
import mastercardLight3XPng from './MastercardLight@3x.png';
import mastercardLight2XWebp from './MastercardLight@2x.webp';
import mastercardLight2XPng from './MastercardLight@2x.png';
import mastercardLight1XWebp from './MastercardLight@1x.webp';
import mastercardLight1XPng from './MastercardLight@1x.png';
export const mastercardLightImage = {
    src: mastercardLight1XPng.src || mastercardLight1XPng,
    width: 49,
    height: 30,
    sources: [
        {
            width: 49,
            height: 30,
            srcSet: mastercardLight3XWebp.src || mastercardLight3XWebp,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 49,
            height: 30,
            srcSet: mastercardLight3XPng.src || mastercardLight3XPng,
            media: '(-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 49,
            height: 30,
            srcSet: mastercardLight2XWebp.src || mastercardLight2XWebp,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 49,
            height: 30,
            srcSet: mastercardLight2XPng.src || mastercardLight2XPng,
            media: '(-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 49, height: 30, srcSet: mastercardLight1XWebp.src || mastercardLight1XWebp },
        { width: 49, height: 30, srcSet: mastercardLight1XPng.src || mastercardLight1XPng },
    ],
};
