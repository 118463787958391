import { ApplyOfferStrategy } from './apply-offer-strategy';
import { StudentStrategy } from './student-strategy';
import { GroupSubscriptionStrategy } from './group-subscription-strategy';
import { EmptyStrategy } from './empty-strategy';
const strategyMap = {
    applyOffer: new ApplyOfferStrategy(),
    student: new StudentStrategy(),
    group_subscription: new GroupSubscriptionStrategy(),
    applyPromocode: new EmptyStrategy(),
    validatePhone: new EmptyStrategy(),
    youngSubscription: new StudentStrategy(),
};
class StrategyFactory {
    constructor() {
        this.strategyMap = strategyMap;
    }
    getStrategy(flow) {
        if (!this.strategyMap[flow]) {
            console.error('Нет такой стратегии');
            return null;
        }
        return this.strategyMap[flow];
    }
}
export default new StrategyFactory();
