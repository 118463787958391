import React from 'react';
import { CustomImg } from '@common/shared/ui/custom-img';
import { unionDarkImage } from './dark';
import { unionLightImage } from './light';
import { useConfigProvider } from '@vkontakte/vkui';
const Icon = (props) => {
    const { appearance } = useConfigProvider();
    const icon = appearance === 'light' ? unionLightImage : unionDarkImage;
    return (<CustomImg {...icon} {...props} alt={'Иконка платежной системы Union pay'}/>);
};
export default Icon;
