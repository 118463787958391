import React from 'react';
import { Icon24ErrorCircleOutline, Icon24CheckCircleOutline, Icon24SearchStarsOutline } from '@vkontakte/icons';
import WarningBannerTemplate from './Templates/WarningBannerTemplate/component';
import styles from './styles.module.scss';
const types = {
    success: {
        theme: 'success',
        icon: (<Icon24CheckCircleOutline width={28} height={28} className={styles.iconSuccess}/>),
    },
    info: {
        theme: 'info',
        icon: (<Icon24SearchStarsOutline width={28} height={28} className={styles.iconInfo}/>),
    },
    attention: {
        theme: 'attention',
        icon: (<Icon24ErrorCircleOutline width={28} height={28} className={styles.iconAttention}/>),
    },
    warning: {
        theme: 'warning',
        icon: (<Icon24ErrorCircleOutline width={28} height={28} className={styles.iconWarning}/>),
    },
};
const WarningBanner = (props) => {
    return (<WarningBannerTemplate {...props} {...types[props.type]}/>);
};
export default WarningBanner;
